import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {boundMethod} from 'autobind-decorator';
import moment, {Moment} from 'moment';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Booking} from '../../../src/lib/models/booking';
import {User} from '../../../src/lib/models/user';
import {Location} from '../../../src/lib/types';
import {api} from '../lib/api-utils';
import {store} from '../lib/notifications';
import LocationSelector from './shared/LocationSelector';

type State = {
    dailyIntolerance: User[],
    dailyBookings: (Booking & User)[],
    currentDate: Moment,
    currentLocation?: Location,
    selectedClass?: string,
    showModal: boolean,
}

class DashboardPrincipale extends Component<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            dailyIntolerance: [],
            dailyBookings: [],
            currentDate: moment(),
            selectedClass: '',
            showModal: false,
        };
    }

    async getIntolerance() {
        const res = await api.get('/reports/intolerance', {
            params: {
                date: this.state.currentDate.format('YYYY-MM-DD'),
                location: this.state.currentLocation,
            }
        });
        this.setState({dailyIntolerance: res.data});
    }

    async getDailyBookings() {
        const res = await api.get(`/bookings/${this.state.currentDate.format('YYYY-MM-DD')}/present`, {
            params: {
                fields: 'firstName,lastName,username,class',
                location: this.state.currentLocation,
            }
        });
        this.setState({dailyBookings: res.data});
    }

    async nextDate() {
        await this.setState({currentDate: this.state.currentDate.add(1, 'day')});
        await this.getDailyBookings();
        await this.getIntolerance();
    }

    async previousDate() {
        if (this.state.currentDate.isAfter(moment())) {
            await this.setState({currentDate: this.state.currentDate.subtract(1, 'day')});
            await this.getDailyBookings();
            await this.getIntolerance();
        } else {
            store.addNotification({
                message: 'Non è possibile visualizzare dati precedenti al giorno attuale.',
                type: 'danger'
            });
        }

    }

    showByClassModal() {
        if (this.state.showModal) {
            return (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                    onHide={() => this.setState({showModal: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Prenotazioni per classe</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderDailyBookingsByClass()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({showModal: false})}>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }

    @boundMethod
    async onSelectLocation(location: Location) {
        await this.setState({currentLocation: location});
        await this.getIntolerance();
        await this.getDailyBookings();
    }

    @boundMethod
    renderIntolerance() {
        if (!this.state.dailyIntolerance || this.state.dailyIntolerance.length === 0) {
            return <span className="font-weight-bold"><br/>Nessuna intolleranza tra le prenotazioni di oggi</span>;
        }
        let {dailyIntolerance} = this.state;
        let intoleranceList = dailyIntolerance.map((intolerance) => {
                return (
                    <tr key={intolerance.username}>
                        <td>
                            {intolerance.username}
                        </td>
                        <td>
                            {intolerance.firstName}
                        </td>
                        <td>
                            {intolerance.lastName}
                        </td>
                        <td>
                            {intolerance.intolerance}
                        </td>
                    </tr>
                );
            }
        );

        return <div><span>Oggi ci sono {dailyIntolerance.length} intolleranze</span>
            <table className="table table-responsive-sm text-white">
                <thead className="text-white">
                <tr>
                    <th scope="col">Nome utente</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Intolleranza</th>
                </tr>
                </thead>
                <tbody>{intoleranceList}</tbody>
            </table>
        </div>;
    }

    @boundMethod
    renderDailyBookings() {
        if (!this.state.dailyBookings || this.state.dailyBookings.length === 0) {
            return <span className="font-weight-bold"><br/>Nessuna prenotazione per oggi</span>;
        }
        let {dailyBookings} = this.state;
        let dailyBookingsList = dailyBookings.map((booking) => {
                return (
                    <tr key={booking.username}>
                        <td>
                            {booking.firstName}
                        </td>
                        <td>
                            {booking.lastName}
                        </td>
                        <td>
                            {booking.username}
                        </td>
                    </tr>
                );
            }
        );

        return <div>
            <span>Oggi ci sono {dailyBookings.length} prenotazioni</span>
            <table className="table table-responsive-sm text-white">
                <thead className="text-white">
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Nome utente</th>
                </tr>
                </thead>
                <tbody>{dailyBookingsList}</tbody>
            </table>
        </div>;

    }

    @boundMethod
    renderDailyBookingsByClass() {
        if (!this.state.dailyBookings || this.state.dailyBookings.length === 0) {
            return <span className="font-weight-bold"><br/>Nessuna prenotazione per oggi</span>;
        }
        let {dailyBookings} = this.state;
        let classes: string[] = [];
        let selectedClass = this.state.selectedClass;
        let numberOfStudents = 0;
        let classList = dailyBookings.map((booking) => {
                if (classes.includes(booking.class)) {
                    return '';
                }
                classes.push(booking.class);
                return (
                    <button key={booking.class} className="dropdown-item"
                            onClick={() => this.setState({selectedClass: booking.class})}>{booking.class}</button>
                );
            }
        );
        let dailyBookingsList = undefined;
        if (selectedClass) {
            dailyBookingsList = dailyBookings.map((booking) => {
                    if (booking.class === selectedClass) {
                        numberOfStudents = numberOfStudents + 1;
                        return (
                            <tr key={booking.username}>
                                <td>
                                    {booking.firstName}
                                </td>
                                <td>
                                    {booking.lastName}
                                </td>
                                <td>
                                    {booking.username}
                                </td>
                                <td>
                                    {booking.class}
                                </td>
                            </tr>
                        );
                    } else {
                        return '';
                    }
                }
            );
        }
        return <div>
            <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Seleziona classe {': ' + this.state.selectedClass}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {classList}
                </div>
            </div>
            <div>
                <h5 className="text-success">Numero allievi: {numberOfStudents}</h5>
            </div>
            <br/>
            <table className="table table-responsive-sm">
                <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Nome utente</th>
                    <th scope="col">Classe</th>
                </tr>
                </thead>
                <tbody>{dailyBookingsList}</tbody>
            </table>
        </div>;
    }

    render() {
        return (

            <div>
                <br/>
                <h1>Dashboard principale</h1>
                <h6>Benvenuti! in questa pagina è possibile avere una panoramica sulle prenotazioni e i dati
                    allergenici
                    al giorno attuale.</h6>
                <Button id="dailyBookingByClassButton" variant="info" size="sm"
                        onClick={() => this.setState({showModal: true})}>
                    Visualizzazione per classe
                </Button>
                {this.showByClassModal()}
                <LocationSelector onSelectLocation={this.onSelectLocation}/>
                <br/>
                <div className="row">
                    <div className="col-md-2">
                        <button className="btn btn-outline-primary btn-sm"
                                onClick={() => this.previousDate()}><FontAwesomeIcon
                            icon={'backward'}/> Giorno precedente
                        </button>
                    </div>
                    <div className="col-md-8">
                        <h4>State visualizzando le informazioni riguardanti la
                            data: {this.state.currentDate.format('DD.MM.YYYY')}</h4>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-outline-primary btn-sm"
                                onClick={() => this.nextDate()}><FontAwesomeIcon
                            icon={'forward'}/> Giorno successivo
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        Prenotazioni:
                        {this.renderDailyBookings()}
                    </div>
                    <div className="col-md-6">
                        Intolleranze:
                        {this.renderIntolerance()}
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardPrincipale;
