import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import Dashboard from './Dashboard';
import Login from './Login';
import {isAuthenticated} from '../lib/api-utils';
import PageNotFound from './PageNotFound';
import * as PropTypes from 'prop-types';
import {Loader} from './shared/Loader';
import Assistenza from './public/Assistenza';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorPage from './ErrorPage';
import {store} from '../lib/notifications';

class AppRouter extends React.Component<{}, {resetKey: number}> {
    constructor(props: {}) {
        super(props);
        this.state = {resetKey: 0};
    }

    onError(err: any) {
        store.addNotification({
            title: err.name || 'Error',
            message: err.message || 'È stato riscontrato un errore.'
        });
        console.error(err);
    }

    render() {
        return (
            <div>
                <ReactNotification/>
                <ErrorBoundary
                    FallbackComponent={ErrorPage}
                    resetKeys={[this.state.resetKey]}
                    onError={this.onError}
                >
                    <Router>
                        <Switch>
                            <Route path='/' exact component={Login}/>
                            <PrivateRoute path='/dashboard' component={Dashboard}/>
                            <Route path='/assistenza/' component={Assistenza}/>
                            <Route component={PageNotFound}/>
                        </Switch>
                    </Router>
                </ErrorBoundary>
            </div>
        );
    }
}

class PrivateRoute extends Route {
    static propTypes = {component: PropTypes.elementType};

    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: false,
            authRequestDone: false,
        };
    }

    async componentDidMount() {
        this.setState({
            isAuthenticated: await isAuthenticated(),
            authRequestDone: true
        });
    }

    render() {
        const {component: Component, ...rest} = this.props;

        return (
            <Route
                {...rest}
                render={props =>
                    // Testing if the authentication was already checked
                    this.state.authRequestDone ? (
                        // If it has already been tested, then test if the user is authenticated
                        this.state.isAuthenticated ? (
                            // If the user is authenticated then return the component
                            // @ts-ignore
                            // Component isn't recognized as valid JSX type by TS
                            <Component {...props} />
                        ) : (
                            // If he's not authenticated redirect him to the login page
                            <Redirect
                                to={{
                                    pathname: '/',
                                    state: {from: props.location}
                                }}
                            />
                        )
                    ) : (
                        // If the authentication was yet not tested then return Loading message...
                        <Loader loading={!this.state.isAuthenticated} message='Caricamento...'/>
                    )
                }
            />
        );
    }
}

export default AppRouter;
