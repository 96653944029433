import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {boundMethod} from 'autobind-decorator';
import moment, {Moment} from 'moment';
import React, {Component, createRef} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {User} from '../../../src/lib/models/user';
import {Location} from '../../../src/lib/types';
import '../assets/css/gestioneutenti.css';
import {api, downloadCsv} from '../lib/api-utils';
import {store} from '../lib/notifications';
import {Loader} from './shared/Loader';
import LocationSelector from './shared/LocationSelector';
import UserSearchBar from './shared/UserSearchBar';

type State = {
    queryString: string,
    gonnaCreateUser: boolean,
    users: User[],
    newFirstName: string,
    newLastName: string,
    newEmail: string,
    newDateOfBirth: Moment,
    newIntolerance: string,
    newAuthorityName: string,
    newAuthorityLastName: string,
    newClassRoom: string,
    newStreet: string,
    newCity: string,
    newCap: string,
    newLocation: Location,
    newUsers?: { response: User[], csvResponse: string },
    updateFirstName?: string,
    updateLastName?: string,
    updateEmail?: string,
    updateDateOfBirth?: Moment,
    updateIntolerance?: string,
    updateLocation?: Location,
    updateUsers?: User[],
    userToEdit?: User,
    updateAuthorityFirstName: string,
    updateAuthorityLastName: string,
    updateClassRoom: string,
    updateStreet: string,
    updateCity: string,
    updateCap: string,
    loading: boolean,
    showModal: boolean,
    importUsersLocation?: Location,
}

/**
 * Component to handle users, containing functions concerning users to interact with the APIs
 */
class GestioneUtenti extends Component<{}, State> {
    private userSearchBarRef = createRef<UserSearchBar>();

    constructor(props: {}) {
        super(props);
        this.state = {
            updateAuthorityFirstName: '',
            updateAuthorityLastName: '',
            updateCap: '',
            updateCity: '',
            updateClassRoom: '',
            updateStreet: '',
            queryString: '',
            gonnaCreateUser: false,
            users: [],
            newFirstName: '',
            newLastName: '',
            newEmail: '',
            newDateOfBirth: moment(),
            newIntolerance: '',
            newAuthorityName: '',
            newAuthorityLastName: '',
            newClassRoom: '',
            newStreet: '',
            newCity: '',
            newCap: '',
            showModal: false,
            newLocation: 'Mesocco',
            updateIntolerance: undefined,
            loading: false
        };
    }

    async registerUser(newEmail: string, newFirstName: string, newLastName: string, newDateOfBirth: Moment, newIntolerance: string,
                       newLocation: Location, newAuthorityName: string, newAuthorityLastName: string, classRoom: string,
                       street: string, city: string, cap: number) {
        // TODO: Checking required field should use centralized validation
        if (newFirstName && newLastName && newDateOfBirth && newLocation) {
            const res = await api.post('/users/register', {
                firstName: newFirstName,
                lastName: newLastName,
                email: newEmail ?? '',
                dateOfBirth: newDateOfBirth.format('YYYY-MM-DD'),
                intolerance: (newIntolerance ?? ''),
                location: newLocation,
                authority: {firstName: newAuthorityName, lastName: newAuthorityLastName},
                class: classRoom,
                address: {street: street, city: city, cap: cap}
            });
            if (res.status !== 201) {
                store.addNotification({message: res.data.message, type: 'danger'});
            }
            store.addNotification({message: 'Utente registrato con successo', type: 'success'});
            this.setState({newUsers: res.data});
        }
    }

    async deleteUser(username: string) {
        let res;
        res = await api.delete(`/users/${username}`);
        if (res.status !== 204) {
            store.addNotification({message: res.data.message, type: 'danger'});
        }
        this.userSearchBarRef.current?.loadUser();
        store.addNotification({
            message: 'Utente cancellato con successo',
            type: 'success'
        });
    }

    async resetPassword(username: string) {
        let res;
        res = await api.put(`/users/${username}/reset-password`);
        res.data.response = [res.data.response];
        res.data.csvResponse = [res.data.csvResponse];
        this.setState({newUsers: res.data});
        store.addNotification({
            message: 'Password reimpostata con successo, controllare i campi sovrastanti',
            type: 'success'
        });
    }

    async updateUser(user: User) {
        user.dateOfBirth = this.state.updateDateOfBirth ? this.state.updateDateOfBirth : user.dateOfBirth;
        user.email = this.state.updateEmail ? this.state.updateEmail : user.email;
        user.firstName = this.state.updateFirstName ? this.state.updateFirstName : user.firstName;
        user.lastName = this.state.updateLastName ? this.state.updateLastName : user.lastName;
        user.location = this.state.updateLocation ? this.state.updateLocation : user.location;
        user.intolerance = this.state.updateIntolerance ?? user.intolerance;
        user.authority.firstName = this.state.updateAuthorityFirstName ? this.state.updateAuthorityFirstName : user.authority.firstName;
        user.authority.lastName = this.state.updateAuthorityLastName ? this.state.updateAuthorityLastName : user.authority.lastName;
        user.class = this.state.updateClassRoom ? this.state.updateClassRoom : user.class;
        user.address.street = this.state.updateStreet ? this.state.updateStreet : user.address.street;
        user.address.city = this.state.updateCity ? this.state.updateCity : user.address.city;
        user.address.cap = this.state.updateCap ? Number(this.state.updateCap) : user.address.cap;

        await api.put(`/users/${user.username}`, user);

        this.setState({
            updateDateOfBirth: undefined,
            updateEmail: undefined,
            updateFirstName: undefined,
            updateLastName: undefined,
            updateLocation: undefined
        });
        await this.userSearchBarRef.current?.loadUser();
        store.addNotification({
            message: 'Utente aggiornato con successo',
            type: 'success'
        });
    }

    async importUsers() {
        console.log(`Importing users for location ${this.state.importUsersLocation}...`);
        try {
            this.setState({loading: true});
            let res;
            res = await api.post('/users/import', {
                location: this.state.importUsersLocation,
            });

            this.setState({newUsers: res.data});
            store.addNotification({message: 'Utenti importati con successo', type: 'success'});
        } catch (e) {
            if (e.response.status === 304) {
                store.addNotification({message: 'Nessun nuovo utente da importare', type: 'info'});
            } else {
                console.log('Could not import users, ' + e.response.status + ' ' + e.response.statusText);
                store.addNotification({
                    message: "Errore nell'importazione degli utenti " + e.response.status + ' ' + e.response.statusText,
                    type: 'danger'
                });
            }
        } finally {
            this.setState({loading: false});
        }
    }

    editUserModal() {
        if (this.state.showModal && this.state.userToEdit) {
            return (
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                    onHide={() => this.setState({showModal: false, userToEdit: undefined})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Dettagli utente</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderEditUser(this.state.userToEdit)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({showModal: false, userToEdit: undefined})}>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }

    @boundMethod
    renderUsersList() {
        if (!this.state.users || this.state.users.length === 0) {
            return null;
        }
        let users = this.state.users;
        let usersList = users.map((user) => {
                return (
                    <tr key={user.username}>
                        <td className="text-white">
                            {user.email}
                        </td>
                        <td className="text-white">
                            {user.firstName}
                        </td>
                        <td className="text-white">
                            {user.lastName}
                        </td>
                        <td className="text-white">
                            {user.location}
                        </td>
                        <td className="text-white font-weight-bold">
                            {user.username}
                        </td>
                        <td>
                            <Button className="btn btn-block" variant="primary"
                                    onClick={() => this.setState({showModal: true, userToEdit: user})}>
                                Dettagli
                            </Button>
                        </td>
                    </tr>
                );
            }
        );
        return <div>
            <br/>
            <table className="table table-responsive-sm userForm">
                <thead className="text-white">
                <tr>
                    <th scope="col" className="th-sm">E-Mail</th>
                    <th scope="col" className="th-sm">Nome</th>
                    <th scope="col" className="th-sm">Cognome</th>
                    <th scope="col" className="th-sm">Sede</th>
                    <th scope="col" className="th-sm">Nome utente</th>
                    <th scope="col" className="th-sm">Azioni</th>
                </tr>
                </thead>
                <tbody>{usersList}</tbody>
            </table>
        </div>;
    }

    @boundMethod
    renderEditUser(user?: User) {
        if (!user) {
            return null;
        }
        console.log('editing user: ' + user.username);
        if (!user.defaultSettings) {
            user.defaultSettings = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            };

        }
        return (
            <div>
                <br/>
                <table className="table table-responsive-sm userForm">
                    <thead>
                    <tr>
                        <th scope="col" className="th-sm">E-Mail</th>
                        <th scope="col" className="th-sm">Nome</th>
                        <th scope="col" className="th-sm">Cognome</th>
                        <th scope="col" className="th-sm">Data di nascita</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input type="email" className="form-control" autoFocus defaultValue={user.email}
                                   onChange={(event) => this.setState({updateEmail: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.firstName}
                                   onChange={(event) => this.setState({updateFirstName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.lastName}
                                   onChange={(event) => this.setState({updateLastName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus
                                   defaultValue={moment(user.dateOfBirth).format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({updateDateOfBirth: event.target.value ? moment(event.target.value) : undefined})}
                            />
                        </td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th scope="col" className="th-sm">Nome utente</th>
                        <th scope="col" className="th-sm">Intolleranze</th>
                        <th scope="col" className="th-sm">defaultSettings</th>
                        <th scope="col" className="th-sm">Sede</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="font-weight-bold">
                            {user.username}
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.intolerance}
                                   onChange={(event) => this.setState({updateIntolerance: event.target.value})}
                            />
                        </td>
                        <td>
                            <ul style={{listStyleType: 'none', margin: '0px', padding: '0px'}}>
                                <li>Lunedì: {user.defaultSettings.monday ? <FontAwesomeIcon icon={'check-circle'}/> :
                                    <FontAwesomeIcon icon={'times-circle'}/>}</li>
                                <li>Martedì: {user.defaultSettings.tuesday ? <FontAwesomeIcon icon={'check-circle'}/> :
                                    <FontAwesomeIcon icon={'times-circle'}/>}</li>
                                <li>Mercoledì: {user.defaultSettings.wednesday ?
                                    <FontAwesomeIcon icon={'check-circle'}/> :
                                    <FontAwesomeIcon icon={'times-circle'}/>}</li>
                                <li>Giovedì: {user.defaultSettings.thursday ? <FontAwesomeIcon icon={'check-circle'}/> :
                                    <FontAwesomeIcon icon={'times-circle'}/>}</li>
                                <li>Venerdì: {user.defaultSettings.friday ? <FontAwesomeIcon icon={'check-circle'}/> :
                                    <FontAwesomeIcon icon={'times-circle'}/>}</li>
                            </ul>
                        </td>
                        <td>
                            <select name="location" className="form-control" autoFocus required
                                    defaultValue={user.location}
                                    onChange={(event) => this.setState({updateLocation: event.target.value as Location})}
                            >
                                <option value="Mesocco">Mesocco</option>
                                <option value="Roveredo">Roveredo</option>
                                <option value="Mesocco-Soazza">Mesocco-Soazza</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th scope="col" className="th-sm">Autorità parentale Nome</th>
                        <th scope="col" className="th-sm">Autorità parentale Cognome</th>
                        <th scope="col" className="th-sm">Classe</th>
                        <th scope="col" className="th-sm">Indirizzo</th>
                        <th scope="col" className="th-sm">Luogo</th>
                        <th scope="col" className="th-sm">CAP</th>
                        <th scope="col" className="th-sm">Azioni</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.authority.firstName}
                                   onChange={(event) => this.setState({updateAuthorityFirstName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.authority.lastName}
                                   onChange={(event) => this.setState({updateAuthorityLastName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.class}
                                   onChange={(event) => this.setState({updateClassRoom: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.address.street}
                                   onChange={(event) => this.setState({updateStreet: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.address.city}
                                   onChange={(event) => this.setState({updateCity: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={user.address.cap}
                                   onChange={(event) => this.setState({updateCap: event.target.value})}
                            />
                        </td>
                        <td>
                            <button className="btn btn-warning btn-block" autoFocus
                                    onClick={() => this.updateUser(user)}>
                                <FontAwesomeIcon icon={'pencil-alt'}/> Aggiorna
                            </button>
                            <button className="btn btn-info btn-block" autoFocus
                                    onClick={() => this.resetPassword(user.username)}>
                                <FontAwesomeIcon icon={'key'}/> Generare nuova password
                            </button>
                            <button className="btn btn-danger btn-block" autoFocus
                                    onClick={() => this.deleteUser(user.username)}>
                                <FontAwesomeIcon icon={'trash-alt'}/> Elimina
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div id="overlayUsersList">{this.renderNewUsersList()}</div>
            </div>
        );
    }

    @boundMethod
    renderNewUsersList() {
        if (!this.state.newUsers) {
            return null;
        }
        const newUsers = this.state.newUsers.response;
        const csv = this.state.newUsers.csvResponse;
        let listItems = newUsers.map((user) => {
                return (
                    <tr key={user.username}>
                        <td>
                            {user.email}
                        </td>
                        <td>
                            {user.firstName}
                        </td>
                        <td>
                            {user.lastName}
                        </td>
                        <td>
                            {moment(user.dateOfBirth).format('DD-MM-YYYY')}
                        </td>
                        <td>
                            {user.location}
                        </td>
                        <td>
                            {user.username}
                        </td>
                        <td>
                            {user.password}
                        </td>
                    </tr>
                );
            }
        );
        return <div>
            <br/>
            <table className="table table-responsive-sm text-white">
                <thead className="text-white">
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">data di nascita</th>
                    <th scope="col">Sede</th>
                    <th scope="col">Nome utente</th>
                    <th scope="col">Password</th>
                </tr>
                </thead>
                <tbody>{listItems}</tbody>
            </table>
            <button className="btn btn-info btn-block" onClick={() => {
                downloadCsv(csv, 'Nuovo Utente-' + moment().format('DD-MM-YYYY'));
            }}>
                <FontAwesomeIcon icon={'download'}/> Scarica export CSV
            </button>
            <br/>
        </div>;
    }

    @boundMethod
    renderNewUserForm() {
        if (this.state.gonnaCreateUser) {
            return (
                <table className="table table-responsive-sm userForm">
                    <thead className="text-white">
                    <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cognome</th>
                        <th scope="col">Data di nascita</th>
                        <th scope="col">Intolleranze</th>
                        <th scope="col">Sede</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input type="email" className="form-control" autoFocus
                                   onChange={(event) => this.setState({newEmail: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newFirstName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newLastName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus
                                   defaultValue={this.state.newDateOfBirth.format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({newDateOfBirth: moment(event.target.value)})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   defaultValue={this.state.newIntolerance}
                                   onChange={(event) => this.setState({newIntolerance: event.target.value})}
                            />
                        </td>
                        <td>
                            <LocationSelector onSelectLocation={location => this.setState({newLocation: location})}/>
                        </td>
                    </tr>
                    </tbody>
                    <thead className="text-white">
                    <tr>
                        <th scope="col">Paternità nome</th>
                        <th scope="col">Paternità cognome</th>
                        <th scope="col">Classe</th>
                        <th scope="col">Via e numero</th>
                        <th scope="col">CAP</th>
                        <th scope="col">Luogo</th>
                        <th scope="col">Azioni</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newAuthorityName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newAuthorityLastName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newClassRoom: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newStreet: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newCap: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="text" className="form-control" autoFocus required
                                   onChange={(event) => this.setState({newCity: event.target.value})}
                            />
                        </td>
                        <td>
                            <button className="btn btn-warning btn-block" autoFocus onClick={() =>
                                this.registerUser(this.state.newEmail, this.state.newFirstName, this.state.newLastName,
                                    this.state.newDateOfBirth, this.state.newIntolerance, this.state.newLocation,
                                    this.state.newAuthorityName, this.state.newAuthorityLastName, this.state.newClassRoom,
                                    this.state.newStreet, this.state.newCity, Number(this.state.newCap))
                            }><FontAwesomeIcon icon={'plus-circle'}/> Aggiungi
                            </button>
                            <button className="btn btn-danger btn-block" autoFocus onClick={() =>
                                this.setState({gonnaCreateUser: false})}><FontAwesomeIcon icon={'undo-alt'}/> Annulla
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            );
        }
    }

    render() {
        return (
            <div>
                <br/>
                <h1>Gestione utenti</h1>
                <UserSearchBar
                    onResult={data => this.setState({users: data})}
                    onError={err => store.addNotification({message: err.message, type: 'danger'})}
                    ref={this.userSearchBarRef}
                />
                <button className="btn btn-lg btn-success btn-block"
                        onClick={() => this.setState({gonnaCreateUser: true})}>
                    <FontAwesomeIcon icon="plus-circle"/> Creare nuovo utente
                </button>
                <br/>
                {this.renderNewUserForm()}
                {this.renderUsersList()}
                {this.renderNewUsersList()}
                <p className="lead">Oppure importa nuovi utenti dai database degli istituti scolastici:</p>
                <LocationSelector
                    onSelectLocation={location => this.setState({importUsersLocation: location})}/>
                <br/>
                <button className="btn btn-lg btn-dark btn-block"
                        onClick={() => this.importUsers()}>
                    <FontAwesomeIcon icon="file-import"/> Importazione automatica utenti / Sincronizzazione modifiche gestionale
                </button>
                <Loader message="Importazione in corso... prego attendere" loading={this.state.loading}><br/></Loader>
                <br/>
                {this.editUserModal()}
            </div>
        );
    }
}

export default GestioneUtenti;
