import React, {Component} from 'react';

class Loader extends Component<{ loading: boolean, message: string }> {
    render() {
        if (this.props.loading) {
            return (
                <div className="text-white"><br/><h1>{this.props.message}</h1><br/>
                    <div className="loader"/>
                </div>
            )
        } else {
            return null;
        }
    }
}

export {Loader};
