import React, {Component} from 'react';
import LocationSelector from './shared/LocationSelector';
import {Button, Col, Row, Tab, Table, Tabs} from 'react-bootstrap';
import {api} from '../lib/api-utils';
import moment from 'moment';
import UserSearchBar from './shared/UserSearchBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import QrReader from 'react-qr-reader';
import {store} from '../lib/notifications';
import {Booking} from '../../../src/lib/models/booking';
import {User} from '../../../src/lib/models/user';
import {Location} from '../../../src/lib/types';
import {boundMethod} from 'autobind-decorator';

class ControlloPresenze extends Component<{}, {
    bookings: (Booking & User)[],
    notConfirmed: (Booking & User)[],
    confirmed: (Booking & User)[],
    users: User[],
    currentLocation?: Location,
    currentTab: string | null
}> {
    constructor(props: {}) {
        super(props);

        this.state = {
            bookings: [],
            notConfirmed: [],
            confirmed: [],
            users: [],
            currentTab: null
        };
    }

    /**
     * Filters current location's bookings and splits them into confirmed and not confirmed.
     *
     * @return {*}
     */
    @boundMethod
    async filterAndSplitBookings() {
        let { bookings } = this.state;
        bookings = bookings.filter(b => b.location === this.state.currentLocation);
        const splitBookings = bookings.reduce((result: (Booking & User)[][], b) => {
                result[b.confirmedPresent ? 1 : 0].push(b);
                return result;
            },
            [[], []]);
        await this.setState({
            notConfirmed: splitBookings[0],
            confirmed: splitBookings[1],
        });
    }

    async getBookings() {
        // Get bookings for all locations for the search feature
        const res = await api.get(`/bookings/${moment().format('YYYY-MM-DD')}/present`, {
            params: {
                fields: 'firstName,lastName,username,location,confirmedPresent',
            }
        });

        await this.setState({
            bookings: res.data,
        });

        // Get current location's bookings and split them into confirmed or not confirmed
        await this.filterAndSplitBookings();
    }

    @boundMethod
    async confirmBooking(username: string) {
        await api.put(`/bookings/${username}/confirm`);
        await this.getBookings();
        store.addNotification({
            message: 'Prenotazione avvenuta e confermata',
            type: 'success'
        });
    }

    @boundMethod
    async unconfirmBooking(username: string) {
        await api.put(`/bookings/${username}/unconfirm`);
        await this.getBookings();
    }

    async componentDidMount() {
        /*console.log('Before mount');
        await this.getBookings();
        console.log('After mount');*/
    }

    @boundMethod
    async onSelectLocation(location: Location) {
        await this.setState({currentLocation: location});
        if (this.state.bookings.length > 0) {
            await this.filterAndSplitBookings();
        } else {
            await this.getBookings();
        }
    }

    async handleScan(data: string | null) {
        if (data) {
            await api.put('/bookings/confirm', {
                id: data
            });
            store.addNotification({
                title: 'Confermato',
                message: 'Presenza confermata con successo',
                type: 'success',
                container: 'top-right',
                dismiss: {
                    duration: 3000,
                    showIcon: true
                }
            });
        }
    }

    handleScanError(err: any) {
        console.error(err);
    }

    renderUsersList() {
        const usersList = [];
        for (const u of this.state.users) {
            // If the user is already confirmed, disable the button
            const booked = this.state.bookings.some(b => b.username === u.username && b.confirmedPresent === true);
            usersList.push(
                <tr key={u.username}>
                    <td>{moment().format('DD-MM-YYYY')}</td>
                    <td>{u.username}</td>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.location}</td>
                    <td>
                        <Button
                            onClick={() => this.confirmBooking(u.username)}
                            disabled={booked}
                        >
                            {booked ? 'Prenotato' : 'Prenota e conferma'}
                        </Button>
                    </td>
                </tr>
            );
        }

        return usersList;
    }

    render() {
        const confirmedList = [];
        const notConfirmedList = [];

        for (const c of this.state.confirmed) {
            confirmedList.push(
                <tr key={c.username}>
                    <td>{moment(c.date).format('DD-MM-YYYY')}</td>
                    <td>{c.username}</td>
                    <td>{c.firstName}</td>
                    <td>{c.lastName}</td>
                    <td>
                        <Button
                            onClick={() => this.unconfirmBooking(c.username)}
                        >
                            Annulla
                        </Button>
                    </td>
                </tr>
            )
        }

        for (const nc of this.state.notConfirmed) {
            notConfirmedList.push(
                <tr key={nc.username}>
                    <td>{moment(nc.date).format('DD-MM-YYYY')}</td>
                    <td>{nc.username}</td>
                    <td>{nc.firstName}</td>
                    <td>{nc.lastName}</td>
                    <td>
                        <Button
                            onClick={() => this.confirmBooking(nc.username)}
                        >
                            Conferma
                        </Button>
                    </td>
                </tr>
            )
        }

        return (
            <div>
                <br/>
                <h1>Controllo presenze</h1>
                <span>Utilizzare questa pagina per confermare (o annullare una conferma) all'entrata della mensa. È
                    possibile tenere traccia degli studenti che si presentano senza precedente prenotazione.
                </span>
                <br/><br/>
                <LocationSelector onSelectLocation={this.onSelectLocation}/>
                <br/>
                <Tabs onSelect={eventKey => this.setState({currentTab: eventKey})} defaultActiveKey="notConfirmed" id="booking-confirmation-tabs">
                    <Tab eventKey="notConfirmed" title="Presenze da confermare">
                        <Table responsive>
                            <thead className="text-white">
                            <tr>
                                <th>Data</th>
                                <th>Utente</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Azioni</th>
                            </tr>
                            </thead>
                            <tbody className="text-white">
                            {notConfirmedList}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="confirmed" title="Presenze confermate">
                        <Table responsive>
                            <thead className="text-white">
                            <tr>
                                <th>Data</th>
                                <th>Utente</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Azioni</th>
                            </tr>
                            </thead>
                            <tbody className="text-white">
                            {confirmedList}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="bookAndConfirm" title="Aggiungere non annunciato">
                        {/*TODO: Better error handling.*/}
                        <UserSearchBar
                            onResult={data => this.setState({users: data})}
                            onError={() => {
                                store.addNotification({
                                    title: 'Errore durante la ricerca',
                                    message: 'Non sono stati forniti dati validi per la ricerca',
                                    type: 'danger'
                                });
                            }}
                        />
                        <Table responsive>
                            <thead className="text-white">
                            <tr>
                                <th>Data</th>
                                <th>Utente</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Istituto</th>
                                <th>Azioni</th>
                            </tr>
                            </thead>
                            <tbody className="text-white">
                            {this.renderUsersList()}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="scanQr" title={<FontAwesomeIcon icon="qrcode"/>}>
                        <Row>
                            <Col xl={{offset: 3, span: 6}}>
                                {/* Lazy load QR reader to avoid spamming camera permissions on page load */}
                                {this.state.currentTab === 'scanQr' ? <QrReader
                                    onError={this.handleScanError}
                                    onScan={this.handleScan}
                                /> : null}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default ControlloPresenze;
