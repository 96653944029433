import React, {Component} from 'react';
import moment, {Moment} from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Loader} from './shared/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {api, downloadXlsx} from '../lib/api-utils';


class EsportarePrenotazioni extends Component<{}, {
    month: Moment,
    loading: boolean
}> {
    constructor(props: {}) {
        super(props);
        this.state = {
            month: moment().subtract(1, 'month').startOf('month'),
            loading: false,
        };
    }

    async exportBookings(month: Moment) {
        this.setState({loading: true});
        const res = await api.get('/reports/billing/monthly', {
            params: {
                // ⚠ 0-indexed!!!
                month: moment(month).month(),
                year: moment(month).year()
            },
            responseType: 'blob',
        });
        downloadXlsx(res.data, 'Report_Prenotazioni_' + moment(month).format('MM-YYYY'));
        this.setState({loading: false});
    }

    datePicker() {
        return (
            <DatePicker
                selected={this.state.month.toDate()}
                onChange={date => this.setState({month: moment(date as Date)})}
                dateFormat="MM/yyyy"
                showMonthYearPicker
            />
        );
    }

    render() {
        return (
            <div>
                <br/>
                <h1>Esportare prenotazioni</h1>
                <span>In questa pagina è possibile esportare le prenotazioni
                        per un mese specifico
                        selezionabile negli appositi campi sottostanti
                </span>
                <br/><br/>
                <div>
                    <label htmlFor="month">Mese: </label><br/>
                    <FontAwesomeIcon icon={'calendar'} style={{marginRight: '15px'}}/>
                    {this.datePicker()}
                </div>
                <br/><br/>
                <button className="btn btn-lg btn-dark btn-block" type="submit"
                        onClick={() => this.exportBookings(this.state.month)}>
                    <FontAwesomeIcon icon={'save'}/> Esportare prenotazioni
                </button>
                <br/>
                <Loader message="Esportazione in corso, prego attendere..." loading={this.state.loading}/>
            </div>
        );
    }
}

export default EsportarePrenotazioni;
