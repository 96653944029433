import React, {Component} from 'react';
import {api} from '../lib/api-utils';
import moment, {Moment} from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {store} from '../lib/notifications';
import {boundMethod} from 'autobind-decorator';
import {Holiday} from '../../../src/lib/models/holiday';

type State = {
    from: Moment,
    to: Moment,
    holidays: Holiday[],
    gonnaCreateHoliday: boolean,
    newFrom: Moment,
    newTo: Moment,
    newName: string,
    updateFrom?: Moment,
    updateTo?: Moment,
    updateName?: string
}

/**
 * Component used to handle holidays, containing functions concerning holidays to interact with the APIs
 */
class GestioneFestivi extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            from: moment(),
            to: moment(),
            holidays: [],
            gonnaCreateHoliday: false,
            newFrom: moment(),
            newTo: moment(),
            newName: '',
        };
    }

    /**
     * Method to get holidays from the APIs
     * @param from
     * @param to
     * @returns {Promise<void>}
     */
    async loadHoliday(from: Moment, to: Moment) {
        const res = await api.get('/holidays', {
            params: {
                from: moment(from).format('YYYY-MM-DD'),
                to: moment(to).format('YYYY-MM-DD')
            }
        });
        this.setState({holidays: res.data});
    }

    /**
     * Method to create a new holiday and upload it to the APIs
     * @param name
     * @param from
     * @param to
     * @returns {Promise<void>}
     */

    async createHoliday(name: string, from: Moment, to: Moment) {
        let res = await api.post('/holidays', {
            fromDate: moment(from).format('YYYY-MM-DD'),
            toDate: moment(to).format('YYYY-MM-DD'),
            name
        });
        if (res.status !== 201) {
            store.addNotification({
                message: `Error loading holidays: ${res.data.message}`,
                type: 'danger'
            })
            console.log('error creating holiday');

            this.setState({newFrom: moment(), newTo: moment(), newName: ''})
            return;
        }
        this.setState({gonnaCreateHoliday: false});
        await this.loadHoliday(this.state.from, this.state.to);
        store.addNotification({
            message: 'Ferie creata con successo',
            type: 'success'
        });
    }

    /**
     * Method to update an holiday (always on the APIs side too).
     *
     * @returns {Promise<void>}
     * @param holiday
     * @param newHoliday
     */
    async updateHoliday(holiday: Holiday, newHoliday: Holiday) {
        const res = await api.put(`/holidays/${moment(holiday.fromDate).format('YYYY-MM-DD')}/${moment(holiday.toDate).format('YYYY-MM-DD')}`, {
            fromDate: moment(newHoliday.fromDate ? newHoliday.fromDate : holiday.fromDate).format('YYYY-MM-DD'),
            toDate: moment(newHoliday.toDate ? newHoliday.toDate : holiday.toDate).format('YYYY-MM-DD'),
            name: newHoliday.name ? newHoliday.name : holiday.name
        });
        if (res.status !== 204) {
            store.addNotification({
                message: `Error updating holiday ${res.data.message}`,
                type: 'danger'
            });
            // Have to assign undefined to unset state field
            this.setState({updateFrom: undefined, updateTo: undefined, updateName: undefined})
            return;
        }
        console.log('holiday updated');
        await this.loadHoliday(this.state.from, this.state.to);
        store.addNotification({
            message: 'Ferie modificata con successo',
            type: 'success'
        });
    }

    /**
     * Method to delete an holiday
     * @param from
     * @param to
     * @returns {Promise<void>}
     */
    async deleteHoliday(from: Moment, to: Moment) {
        let res;
        res = await api.delete(`/holidays/${from.format('YYYY-MM-DD')}/${to.format('YYYY-MM-DD')}`);
        if (res.status !== 204) {
            store.addNotification({
                message: `Error deleting holiday: ${res.data.message}`,
                type: 'danger'
            });
            return;
        }
        console.log('holiday deleted');
        await this.loadHoliday(this.state.from, this.state.to);
        store.addNotification({
            message: 'Ferie cancellata con successo',
            type: 'success'
        });
    }

    /**
     * Method called by render() to render the table with the holidays from the APIs
     * @return when there's no holiday to show
     */
    @boundMethod
    renderHolidaysList() {
        if (!this.state.holidays || this.state.holidays.length === 0) {
            return null;
        }
        let holidays = this.state.holidays;
        let holidaysList = holidays.map((holiday) => {
                return (
                    <tr key={(moment(holiday.fromDate).toISOString() + moment(holiday.toDate).toISOString())}>
                        <td>
                            <input type="text" className="form-control" autoFocus defaultValue={holiday.name}
                                   onChange={(event) => this.setState({updateName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus
                                   defaultValue={moment(holiday.fromDate).format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({updateFrom: moment(event.target.value)})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus
                                   defaultValue={moment(holiday.toDate).format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({updateTo: moment(event.target.value)})}
                            />
                        </td>
                        <td>
                            <button className="btn btn-warning btn-block" autoFocus
                                    onClick={() => this.updateHoliday(
                                        holiday,
                                        {
                                            fromDate: this.state.updateFrom,
                                            toDate: this.state.updateTo,
                                            name: this.state.updateName
                                        }
                                    )}><FontAwesomeIcon icon={'pencil-alt'}/> Aggiorna
                            </button>
                            <button className="btn btn-danger btn-block" autoFocus
                                    onClick={() => (this.deleteHoliday(moment(holiday.fromDate), moment(holiday.toDate)))}>
                                <FontAwesomeIcon
                                    icon={'trash-alt'}/> Elimina
                            </button>
                        </td>
                    </tr>
                )
            }
        );
        return <table className="table table-responsive-sm">
            <thead className="text-white">
            <tr>
                <th scope="col">Nome</th>
                <th scope="col">Inizio</th>
                <th scope="col">Fine</th>
                <th scope="col">Azioni</th>
            </tr>
            </thead>
            <tbody>{holidaysList}</tbody>
        </table>;
    }

    /**
     * Method to show the prompt allowing the user to input a new holiday and create it
     * @return {null} when not going to add an holiday
     */
    @boundMethod
    renderCreateHolidayForm() {
        const {gonnaCreateHoliday} = this.state;
        if (gonnaCreateHoliday) {
            return (
                <table className="table table-responsive-sm">
                    <thead className="text-white">
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Inizio</th>
                        <th scope="col">Fine</th>
                        <th scope="col">Azioni</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input type="text" className="form-control" autoFocus
                                   value={this.state.newName} required
                                   onChange={(event) => this.setState({newName: event.target.value})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus required
                                   value={this.state.newFrom.format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({newFrom: moment(event.target.value)})}
                            />
                        </td>
                        <td>
                            <input type="date" className="form-control" autoFocus required
                                   value={this.state.newTo.format('YYYY-MM-DD')}
                                   onChange={(event) => this.setState({newTo: moment(event.target.value)})}
                            />
                        </td>
                        <td>
                            <button className="btn btn-warning btn-block" autoFocus onClick={() =>
                                this.createHoliday(this.state.newName, this.state.newFrom, this.state.newTo)}>
                                <FontAwesomeIcon icon={'plus-circle'}/> Aggiungi
                            </button>
                            <button className="btn btn-danger btn-block" autoFocus onClick={() =>
                                this.setState({gonnaCreateHoliday: false})}><FontAwesomeIcon icon={'undo-alt'}/> Annulla
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            );
        }
    }

    render() {
        return (
            <div>
                <br/>
                <h1>Gestione festivi</h1>
                <span>In questa pagina è possibile visualizzare i giorni festivi inseriti
                        all'interno di un periodo selezionabile negli appositi campi sottostanti
                </span>
                <br/>
                <label htmlFor="from">Da:</label>
                <input type="date" id="from" className="form-control" required
                       value={this.state.from.format('YYYY-MM-DD')}
                       autoFocus
                       onChange={(event) => this.setState({from: moment(event.target.value)})}/>
                <br/>
                <label htmlFor="to">A:</label>
                <input type="date" id="to" className="form-control" required value={this.state.to.format('YYYY-MM-DD')}
                       autoFocus
                       onChange={(event) => this.setState({to: moment(event.target.value)})}/>
                <br/>
                <button className="btn btn-lg btn-primary btn-block" type="submit"
                        onClick={() => this.loadHoliday(this.state.from, this.state.to)}><FontAwesomeIcon
                    icon={'binoculars'}/> Visualizzare festività
                </button>
                <br/>
                {this.renderHolidaysList()}
                <button className="btn btn-lg btn-success btn-block"
                        onClick={() => this.setState({gonnaCreateHoliday: true})}>
                    <FontAwesomeIcon icon={'plus-circle'}/> Creare nuova festività
                </button>
                <br/>
                {this.renderCreateHolidayForm()}
                <br/>
            </div>
        );
    }
}

export default GestioneFestivi;
