import React, {Component} from 'react';
import moment, {Moment} from 'moment';
import {api} from '../lib/api-utils';
import UserSearchBar from './shared/UserSearchBar';
import Button from 'react-bootstrap/Button';
import {User} from '../../../src/lib/models/user';
import {Booking} from '../../../src/lib/models/booking';
import {store} from '../lib/notifications';

type State = {
    queryResults: User[],
    from: Moment,
    to: Moment,
    bookings: Booking[],
    user?: string
}

class Prenotazioni extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            queryResults: [],
            from: moment(),
            to: moment().add(1, 'year'),
            bookings: []
        };

        // Bind methods
        this.renderBookingsList = this.renderBookingsList.bind(this);
        this.loadBookings = this.loadBookings.bind(this);
    }

    async loadBookings(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (event) {
            event.preventDefault();
        }
        const {from, to, user} = this.state;
        if (!user) {
            store.addNotification({
                message: 'Per favore inserire un nome utente da ricercare',
                type: 'danger'
            });
            return;
        }
        const res = await api.get('/bookings', {
            params: {
                from: from.format('YYYY-MM-DD'),
                to: to.format('YYYY-MM-DD'),
                username: user,
            }
        });
        this.setState({bookings: res.data});
    }

    renderBookingsList() {
        if (!this.state.bookings || this.state.bookings.length === 0) {
            return;
        }
        let bookings = this.state.bookings;
        let bookingsList = bookings.map((booking) => {
                return (
                    <tr key={moment(booking.date).toISOString()}>
                        <td>
                            {moment(booking.date).format('DD-MM-YYYY')}
                        </td>
                        <td>
                            {booking.username}
                        </td>
                        <td>
                            {booking.isPresent || booking.confirmedPresent ? 'Presente' : 'Assente'}
                        </td>
                        <td>
                            {booking.isDefault ? 'Automatico' : booking.isPresent ? "Selezionato dall'utente" : 'Selezionato dal sorvegliante'}
                        </td>
                    </tr>
                )
            }
        );
        return <table className="table table-responsive-sm text-white">
            <thead className="text-white">
            <tr>
                <th scope="col">Data</th>
                <th scope="col">Utente</th>
                <th scope="col">Presente?</th>
                <th scope="col">Metodo di prenotazione</th>
            </tr>
            </thead>
            <tbody>{bookingsList}</tbody>
        </table>;
    }

    renderResults() {
        if (this.state.queryResults) {
            // Create a radio button for each user
            const buttons = this.state.queryResults.map(user => <Button
                className="mr-2"
                key={user.username}
                onClick={async event => {
                    await this.setState({user: user.username});
                    await this.loadBookings(event);
                }}
                active={this.state.user === user.username}>
                {`${user.firstName} ${user.lastName}`}
            </Button>);
            return (
                <div className="text-center">
                    {buttons}
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <br/>
                <h1>Prenotazioni</h1>
                <span>In questa pagina è possibile visualizzare le prenotazioni
                        per un utente specifico
                        selezionabile negli appositi campi sottostanti
                </span>
                <br/>
                <br/>
                <label htmlFor="from">Da:</label>
                <input type="date" id="from" className="form-control" required
                       value={this.state.from.format('YYYY-MM-DD')}
                       autoFocus
                       onChange={(event) => this.setState({from: moment(event.target.value)})}/>
                <br/>
                <label htmlFor="to">A:</label>
                <input type="date" id="to" className="form-control" required value={this.state.to.format('YYYY-MM-DD')}
                       autoFocus
                       onChange={(event) => this.setState({to: moment(event.target.value)})}/>
                <br/>
                <UserSearchBar
                    fields={['firstName', 'lastName', 'username']}
                    onResult={data => this.setState({queryResults: data})}
                />
                <br/>
                {this.renderResults()}
                <br/>
                {this.renderBookingsList()}
            </div>
        );
    }
}

export default Prenotazioni;

