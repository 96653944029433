import React from 'react';

function Assistenza() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-1"/>
                <div id="content" className="col-md-10">
                    <span>Per ricevere assistenza riguardo all'utilizzo dell'app o segnalare bug, rivolgersi a <a
                        href="mailto:nathan.lepori@hotmail.com">nathan.lepori@hotmail.com</a>.</span>
                </div>
                <div className="col-md-1"/>
            </div>
        </div>
    );
}

export default Assistenza;
