import {ReactNotificationOptions, store as _store} from 'react-notifications-component';

interface CustomStore {
    addNotification: (options: Partial<ReactNotificationOptions>) => string;
    removeNotification: (id: string) => void;
}

const DEFAULT_OPTIONS: ReactNotificationOptions = {
    container: 'top-right',
    dismiss: {
        duration: 0,
        showIcon: true,
    }
};

export const store: CustomStore = {
    addNotification: (options: Partial<ReactNotificationOptions>) => {
        options = Object.assign(DEFAULT_OPTIONS, options);
        return _store.addNotification(options as ReactNotificationOptions);
    },
    removeNotification: _store.removeNotification,
};
