import React, {Component} from 'react';
import {Nav} from 'react-bootstrap';
import {api} from '../../lib/api-utils';
import {Location} from '../../../../src/lib/types';

type Props = {
    onSelectLocation: (location: Location) => void
};

type State = {
    currentLocation: Location | undefined,
    locations: Location[]
}

class LocationSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            locations: [],
            // Potentially null
            currentLocation: localStorage.getItem('currentLocation') as (Location | undefined),
        };

        // Bind methods
        this.onSelectLocation = this.onSelectLocation.bind(this);

        // Call this in constructor to set location on dependent components before componentDidMount hook
        if (this.props.onSelectLocation && this.state.currentLocation) {
            this.props.onSelectLocation(this.state.currentLocation);
        }
    }

    async componentDidMount() {
        const res = await api.get('/authorization/locations');
        const locations = res.data;
        const location = locations[0];

        // If currentLocation has never been stored, or authorization for location was revoked, pick the first one and
        // set localStorage
        if (!this.state.currentLocation || !locations.includes(this.state.currentLocation)) {
            console.log(`Set default location to ${location}`);
            await this.setState({
                locations,
                currentLocation: location
            });
            localStorage.setItem('currentLocation', location);
            // Call the callback at the earliest possible moment after API call (cannot be done in constructor)
            if (this.props.onSelectLocation) {
                await this.props.onSelectLocation(location);
            }
        } else {
            // Just set locations
            this.setState({
                locations: res.data,
            });
        }
    }

    async onSelectLocation(location: string | null) {
        if (!location) {
            return;
        }
        console.log(`Changed location to ${location}`);
        await this.setState({currentLocation: location as Location});
        localStorage.setItem('currentLocation', location);
        if (this.props.onSelectLocation) {
            await this.props.onSelectLocation(location as Location);
        }
    }

    render() {
        const locationTabs = [];
        for (const l of this.state.locations) {
            locationTabs.push(
                <Nav.Item key={l}>
                    <Nav.Link
                        eventKey={l}
                        title={l}
                    >
                        {l}
                    </Nav.Link>
                </Nav.Item>
            );
        }

        return (
            <Nav
                variant="pills"
                activeKey={this.state.currentLocation}
                id="location-tabs"
                onSelect={this.onSelectLocation}
            >
                {locationTabs}
            </Nav>
        );
    }
}

export default LocationSelector;
