import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api} from '../../lib/api-utils';
import {User, UserFields} from '../../../../src/lib/models/user';
import {Button, Form} from 'react-bootstrap';
import {boundMethod} from 'autobind-decorator';

type Props = {
    fields?: UserFields[];
    onResult: (result: User[]) => void;
    onError?: (err: any) => void;
}

class UserSearchBar extends Component<Props, { queryString: string }> {
    constructor(props: Props) {
        super(props);
        this.state = {
            queryString: '',
        };
    }

    @boundMethod
    async loadUser(event?: React.FormEvent<HTMLElement>) {
        event?.preventDefault();
        const {queryString} = this.state;
        if (queryString) {
            let res;
            res = await api.get('/users/search', {
                params: {
                    query: queryString,
                    fields: this.props.fields ? this.props.fields.join(',') : null,
                }
            });
            console.log(`Search results: ${res.data}`)
            if (this.props.onResult) {
                this.props.onResult(res.data);
            }
        } else {
            console.log('no valid arguments to load have been provided');
            if (this.props.onError) {
                this.props.onError({message: 'Non sono stati forniti dati validi per la ricerca'});
            }
        }
    }

    render() {
        return (
            <Form onSubmit={this.loadUser}>
                <Form.Label htmlFor="search">Nome, cognome, nome utente o email:</Form.Label>
                <Form.Control id="search" type="text" required value={this.state.queryString}
                              autoFocus
                              onChange={(event) => this.setState({queryString: event.target.value})}/>
                <br/>
                <Button variant="primary" size="lg" type="submit" block>
                    <FontAwesomeIcon icon="search"/> Cerca
                </Button>
                <br/>
            </Form>
        );
    }
}

export default UserSearchBar;
