import React, {Component} from 'react';
import {api, getStoreToken} from '../lib/api-utils';
import '../assets/css/login.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {boundMethod} from 'autobind-decorator';
import {RouteComponentProps} from 'react-router-dom';
import {store} from '../lib/notifications';
import {Button, Card, Col, Container, Form, InputGroup, Row} from 'react-bootstrap';

type State = {
    username: string,
    password: string,
    loginFailed: boolean,
    showPassword: boolean,
}

class Login extends Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginFailed: false,
            showPassword: false,
        };
    }

    @boundMethod
    async doLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const {username, password} = this.state;
        let res;
        try {
            // Send login request
            res = await api.post('/users/login', {
                username,
                password,
                admin: true
            });
            await api.setToken(res.data.token);

            console.log(res.data.token === await getStoreToken() ? 'token updated successfully' : 'error updating token');

            this.props.history.push('/dashboard');
        } catch (e) {
            if (e.response.status === 401) {
                // Login failed
                console.log('login failed');
                store.addNotification({
                    title: 'Login fallito',
                    message: 'Accesso fallito, nome utente o password errati...',
                    type: 'danger'
                });
            } else {
                throw e;
            }
        }
    }

    @boundMethod
    toggleShowPassword() {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    }

    render() {
        const {showPassword} = this.state;

        return (
            <Container fluid>
                <br/>
                <Row>
                    <Col md={{
                        offset: 4,
                        span: 4
                    }}>
                        <Card id="loginbox">
                            <Card.Body>
                                <h2>Effettuare l'accesso</h2>
                                <Form onSubmit={this.doLogin}>
                                    <Form.Label htmlFor="username">Email o nome utente:</Form.Label>
                                    <Form.Control id="username" type="text" autoComplete={'username'} required autoFocus
                                                  onChange={(event) => this.setState({username: event.target.value})}/>
                                    <Form.Label htmlFor="password">Password:</Form.Label>
                                    <InputGroup>
                                        <Form.Control id="password" type={showPassword ? 'text' : 'password'} autoComplete={'current-password'} required
                                                      onChange={(event) => this.setState({password: event.target.value})}/>
                                        <InputGroup.Append>
                                            <Button variant="outline-light"
                                                    onClick={this.toggleShowPassword}><FontAwesomeIcon
                                                icon={showPassword ? 'eye-slash' : 'eye'}/></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <br/>
                                    <Button size="lg" variant="primary" block type="submit"><FontAwesomeIcon
                                        icon={'sign-in-alt'}/> Accedere
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Login;
