import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import publicKeyUrl from '../assets/id_rsa.pub';

const authUrl = '/token';

interface ApiInstance extends AxiosInstance {
    setToken: (token: string) => void,
    clearToken: () => void,
    restoreToken: () => Promise<void>
}

// Extra methods defined below
const api: ApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
    },
}) as ApiInstance;

function getStoreToken() {
    return sessionStorage.getItem('token');
}

function setStoreToken(token: string) {
    sessionStorage.setItem('token', token);
}

function getClientToken() {
    return api.defaults.headers.common.Authorization;
}

function setClientToken(token: string, config?: AxiosRequestConfig) {
    const authHeader = `Bearer ${token}`;

    if (config) {
        // Set header on this request. Axios doesn't set common headers until the next request
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = authHeader;
    }

    // Set Authorization header on every request from now on
    api.defaults.headers.common.Authorization = authHeader;
}

function setToken(token: string) {
    setClientToken(token);
    return setStoreToken(token);
}

function clearToken() {
    delete api.defaults.headers.common.Authorization;
    return sessionStorage.removeItem('token');
}

/**
 * Restores the default, unauthenticated token.
 *
 * @return {Promise<void>}
 */
async function restoreToken() {
    delete api.defaults.headers.common.Authorization;
    // Force to reload token
    await checkAndSetToken();
}

async function requestNewToken() {
    // Download public key
    const publicKey = await (await fetch(publicKeyUrl)).text();

    // Do request
    const res = await api.post<{ token: string }>(authUrl, {publicKey});

    // Get token from HTTP response
    return res.data.token;
}

/**
 * Checks if a token is available, and if it's not, requests a new one to the API. Refer to the
 * block comment on the top of this file for details.
 * @return {Promise<boolean>} Whether the token was already available on the device.
 */
async function checkAndSetToken(config?: AxiosRequestConfig) {
    // Token already set. Go on
    if (getClientToken()) {
        return true;
    }

    // Try to get token from store
    let token = getStoreToken();

    // There's a valid token stored
    if (token) {
        setClientToken(token, config);
        return true;
    }

    // Token needs to be requested from API
    token = await requestNewToken();

    // Set token on the client
    setClientToken(token, config);

    // Set token in store to preserve it across restarts
    setStoreToken(token);

    // Token wasn't available
    return false;
}

api.interceptors.request.use(async (config) => {
    // Skip if requesting token (prevents calling checkAndSetToken recursively)
    if (config.url === authUrl) {
        return config;
    }

    // Renew token if necessary and go on with the request
    await checkAndSetToken(config);
    return config;
});

api.interceptors.response.use(res => res, async (err) => {
    // If the token is expired, refresh it
    if (err.response && err.response.status === 401 && err.response.data.name === 'TokenExpiredError') {
        // Token needs to be requested from API
        const token = await requestNewToken();

        // Set token on the client
        setClientToken(token, err.config);

        // Set token in store to preserve it across restarts
        setStoreToken(token);

        // Replay request
        return api.request(err.config);
    }
    throw err;
});

api.setToken = setToken;
api.clearToken = clearToken;
api.restoreToken = restoreToken;

/**
 * Function to determine if a user is authenticated, interacting with the APIs to get the username declared in the token
 * @returns {Promise<boolean>}
 */
async function isAuthenticated() {
    try {
        const res = await api.get('/users/current', {
            params: {
                fields: 'username'
            }
        });
        return res.data.username && res.data.status !== 404;
    } catch (e) {
        if (e.response.status === 401) {
            return false;
        } else {
            throw e;
        }
    }
}

async function isAuthorized(path: string): Promise<boolean>;
async function isAuthorized(path: readonly string[]): Promise<boolean[]>;
async function isAuthorized(path: string | readonly string[]): Promise<boolean | boolean[]>;
async function isAuthorized(path: string | readonly string[]): Promise<boolean | boolean[]> {
    if (!Array.isArray(path)) {
        try {
            await api.post('/authorization', {
                path,
                // For now just use GET. No post method is used in React AFAIK
                method: 'GET',
            });
            return true;
        } catch (e) {
            if (e.response.status === 401) {
                return false;
            } else {
                throw e;
            }
        }
    } else {
        const routes = path.map(p => ({
            path: p,
            method: 'GET'
        }));

        const res = await api.post<('Allow' | 'Deny')[]>('/authorization', routes);
        // Map routes to booleans
        return res.data.map(p => p === 'Allow');
    }
}

function downloadCsv(data: BlobPart, filename: string) {
    const url = URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + '.csv');
    document.body.appendChild(link);
    link.click();
    (link.parentNode as Node).removeChild(link);
}

function downloadXlsx(data: any, filename: string) {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + '.xlsx');
    document.body.appendChild(link);
    link.click();
    (link.parentNode as Node).removeChild(link);
}

export {
    api,
    isAuthenticated,
    isAuthorized,
    getStoreToken,
    downloadCsv,
    downloadXlsx
};
