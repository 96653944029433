import React, {Component} from 'react';
import {api, isAuthorized} from '../lib/api-utils';
import {Link, Redirect, Route, RouteComponentProps, RouteProps, } from 'react-router-dom';
import '../assets/css/dashboard.css';
import Prenotazioni from './Prenotazioni';
import DashboardPrincipale from './DashboardPrincipale';
import GestioneFestivi from './GestioneFestivi';
import GestioneUtenti from './GestioneUtenti';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EsportarePrenotazioni from './EsportarePrenotazioni';
import ControlloPresenze from './ControlloPresenze';
import * as PropTypes from 'prop-types';
import {Loader} from './shared/Loader';
import {Col, Row} from 'react-bootstrap';
import {Environment} from '../../../src/lib/environment';
import {boundMethod} from 'autobind-decorator';

type NavItem = {
    to: string,
    text: string,
}

library.add(fas);

const NAV_ITEMS: NavItem[] = [
    {
        to: '/dashboard',
        text: 'Pagina iniziale'
    },
    {
        to: '/dashboard/prenotazioni',
        text: 'Prenotazioni'
    },
    {
        to: '/dashboard/controllo-presenze',
        text: 'Controllo presenze'
    },
    {
        to: '/dashboard/gestione-festivi',
        text: 'Gestione festivi'
    },
    {
        to: '/dashboard/gestione-utenti',
        text: 'Gestione utenti'
    },
    {
        to: '/dashboard/esportare-prenotazioni',
        text: 'Esportare Prenotazioni'
    },
];

class Dashboard extends Component<RouteComponentProps, {
    navItems: NavItem[],
    apiInfo?: {
        version: string,
        environment: Environment
    }
}> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            navItems: [],
        };
    }

    async componentDidMount() {
        const paths = NAV_ITEMS.map(i => i.to);
        const [navItems, apiInfo] = await Promise.all([
            // Query policies to the API
            isAuthorized(paths)
                .then(policies => {
                    // Filter items according to policies and map to UI elements
                    return NAV_ITEMS.filter((_, index) => policies[index])
                }),
            // Get API info
            api.get('/info')
                .then(res => res.data)
        ]);

        // Redirect to first available page if current page isn't authorized
        if (!paths.includes(this.props.location.pathname)) {
            this.props.history.push(navItems[0].to);
        }

        this.setState({
            navItems,
            apiInfo
        });
    }

    /**
     * Method to log out an user deleting the token stored in the session storage
     * @returns {Promise<void>}
     */
    logout() {
        api.clearToken();
        this.props.history.push('/');
    }

    @boundMethod
    renderNavItems() {
        // Filter items according to policies and map to UI elements
        return this.state.navItems.map((i, index) => (
            <li className="nav-item" key={index}>
                <Link className="nav-link" to={i.to}>{i.text}</Link>
            </li>
        ));
    }

    render() {
        return (
            <div>
                <nav role="navigation" className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                    <Link className="navbar-brand" to={'/dashboard'}>Mensa Moesano</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {this.renderNavItems()}
                            <li className="nav-item">
                                <Link className="nav-link btn btn-warning" to={'/'}
                                      onClick={() => this.logout()}><FontAwesomeIcon
                                    icon={'sign-out-alt'}/> Esci</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <br/><br/><br/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1"/>
                        <div id="content" className="col-md-10">
                            <AuthorizedRoute path="/dashboard" exact component={DashboardPrincipale}/>
                            <AuthorizedRoute path="/dashboard/prenotazioni" exact component={Prenotazioni}/>
                            <AuthorizedRoute path="/dashboard/controllo-presenze" exact component={ControlloPresenze}/>
                            <AuthorizedRoute path="/dashboard/gestione-festivi" exact component={GestioneFestivi}/>
                            <AuthorizedRoute path="/dashboard/gestione-utenti" exact component={GestioneUtenti}/>
                            <AuthorizedRoute path="/dashboard/esportare-prenotazioni" exact
                                             component={EsportarePrenotazioni}/>
                        </div>
                        <div className="col-md-1"/>
                    </div>
                </div>
                <div className="font-weight-bold footer-copyright py-3 fixed-bottom" id="footer">
                    <Row>
                        <Col lg={{span: 6, offset: 3}} className="text-center">
                            © La piattaforma è stata realizzata da <a href="https://ch.linkedin.com/in/nathan-lepori-ab6612124">Nathan
                            Lepori</a> e <a href="https://ch.linkedin.com/in/sergio-conti-rossini-97804414a">Sergio Conti Rossini</a>
                        </Col>
                        <Col className="text-right">
                            <small className="text-muted line-height-small">
                                <p className="mb-0 line-height-small">Version: {process.env.REACT_APP_VERSION}</p>
                                <p className="mb-0">API version: {this.state.apiInfo?.version} ({this.state.apiInfo?.environment})</p>
                            </small>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

class AuthorizedRoute extends Route {
    static propTypes = {component: PropTypes.elementType};

    constructor(props: RouteProps) {
        super(props);
        // ⚠ No type checking for state: route class declares it as any
        this.state = {
            isAuthorized: false,
            authRequestDone: false,
        };
    }

    async componentDidMount() {
        const authorized = this.props.path ? Array.isArray(this.props.path) ?
            (await isAuthorized(this.props.path)).every(p => p) : await isAuthorized(this.props.path) : true;
        this.setState({
            isAuthorized: authorized,
            authRequestDone: true
        });
    }

    render() {
        const {component: Component, ...rest} = this.props;

        return (
            <Route
                {...rest}
                render={props =>
                    // Testing if the authentication was already checked
                    this.state.authRequestDone ? (
                        // If it has already been tested, then test if the user is isAuthorized
                        this.state.isAuthorized ? (
                            // If the user is authorized then return the component
                            // @ts-ignore
                            // Component isn't recognized as valid JSX type by TS
                            <Component {...props} />
                        ) : (
                            // If he's not authorized redirect him to the not found page
                            <Redirect
                                to={{
                                    pathname: '/not-found',
                                    state: {from: props.location}
                                }}
                            />
                        )
                    ) : (
                        // If the authentication was yet not tested then return Loading message...
                        <Loader loading={!this.state.isAuthorized} message="Caricamento..."/>
                    )
                }
            />
        );
    }
}

export default Dashboard;
